var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('FormBlock',{attrs:{"title":"Data Retrieval Details","description":("The exact SQL statement or the table/view to query and retrieve data from your " + _vm.type)}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"pt-1 space-x-4",class:{ 'text-red-600': errors.length > 0 }},[_c('label',{staticClass:"inline-flex items-center"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.retrievalDetailsConfiguration.method),expression:"retrievalDetailsConfiguration.method"}],staticClass:"text-primary-600 form-radio disabled:text-neutral-400 disabled:cursor-not-allowed",attrs:{"type":"radio","name":"method","disabled":_vm.disabled},domProps:{"value":_vm.SQLRetrievalMethod.Query,"checked":_vm._q(_vm.retrievalDetailsConfiguration.method,_vm.SQLRetrievalMethod.Query)},on:{"change":[function($event){return _vm.$set(_vm.retrievalDetailsConfiguration, "method", _vm.SQLRetrievalMethod.Query)},function($event){return _vm.methodChanged(_vm.SQLRetrievalMethod.Query)}]}}),_c('span',{staticClass:"ml-2 text-sm text-neutral-700"},[_vm._v("SQL Statement")])]),_c('label',{staticClass:"inline-flex items-center"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.retrievalDetailsConfiguration.method),expression:"retrievalDetailsConfiguration.method"}],staticClass:"text-primary-600 form-radio disabled:text-neutral-400 disabled:cursor-not-allowed",attrs:{"type":"radio","name":"method","disabled":_vm.disabled},domProps:{"value":_vm.SQLRetrievalMethod.Table,"checked":_vm._q(_vm.retrievalDetailsConfiguration.method,_vm.SQLRetrievalMethod.Table)},on:{"change":[function($event){return _vm.$set(_vm.retrievalDetailsConfiguration, "method", _vm.SQLRetrievalMethod.Table)},function($event){return _vm.methodChanged(_vm.SQLRetrievalMethod.Table)}]}}),_c('span',{staticClass:"ml-2 text-sm text-neutral-700"},[_vm._v("Table/View")])])]),(errors.length > 0)?_c('div',{staticClass:"mt-1 text-sm text-red-700"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}])}),(_vm.retrievalDetailsConfiguration.method === _vm.SQLRetrievalMethod.Query)?_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"space-y-4"},[_vm._t("sql-syntax-guidelines"),_c('SQLEditor',{staticClass:"h-40 p-4",attrs:{"readonly":_vm.disabled},on:{"change":function($event){return _vm.$emit('changed')}},model:{value:(_vm.retrievalDetailsConfiguration.query),callback:function ($$v) {_vm.$set(_vm.retrievalDetailsConfiguration, "query", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"retrievalDetailsConfiguration.query"}}),(errors.length > 0)?_c('div',{staticClass:"text-sm text-red-700"},[_vm._v("SQL Statement is required")]):(_vm.invalid)?_c('div',{staticClass:"text-sm text-red-700"},[_vm._v("Invalid SQL statement")]):(_vm.containsModificationTerms)?_c('div',{staticClass:"text-sm text-red-700"},[_vm._v(" SQL statement must not contain any modification terms. ")]):_vm._e()],2)]}}],null,true)}):(_vm.retrievalDetailsConfiguration.method === _vm.SQLRetrievalMethod.Table)?_c('div',[_c('ValidationProvider',{attrs:{"rules":"required|max:300"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"flex items-top"},[_c('label',{staticClass:"w-40 mt-2 text-sm text-neutral-700",attrs:{"for":"table/view"}},[_vm._v("Table/View")]),_c('div',{staticClass:"flex flex-col flex-1"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.retrievalDetailsConfiguration.table),expression:"retrievalDetailsConfiguration.table",modifiers:{"trim":true}}],staticClass:"block h-10 text-sm form-input",class:{ 'border-red-600': errors.length > 0 },attrs:{"name":"table/view","placeholder":"Enter table/view name","type":"text","max":"300","disabled":_vm.disabled},domProps:{"value":(_vm.retrievalDetailsConfiguration.table)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.$set(_vm.retrievalDetailsConfiguration, "table", $event.target.value.trim())},function($event){return _vm.$emit('changed')}],"blur":function($event){return _vm.$forceUpdate()}}}),(errors.length > 0)?_c('div',{staticClass:"mt-1 text-sm text-red-700"},[_vm._v(_vm._s(errors[0]))]):_vm._e()])])]}}])}),(_vm.retrievalDetailsConfiguration.databaseSchema !== undefined)?_c('ValidationProvider',{attrs:{"rules":"required|max:300"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"flex mt-4 items-top"},[_c('label',{staticClass:"w-40 mt-2 text-sm text-neutral-700",attrs:{"for":"database_schema"}},[_vm._v("Schema")]),_c('div',{staticClass:"flex flex-col flex-1"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.retrievalDetailsConfiguration.databaseSchema),expression:"retrievalDetailsConfiguration.databaseSchema",modifiers:{"trim":true}}],staticClass:"block h-10 text-sm form-input",class:{ 'border-red-600': errors.length > 0 },attrs:{"name":"database_schema","placeholder":"Enter database schema","type":"text","max":"300","disabled":_vm.disabled},domProps:{"value":(_vm.retrievalDetailsConfiguration.databaseSchema)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.$set(_vm.retrievalDetailsConfiguration, "databaseSchema", $event.target.value.trim())},function($event){return _vm.$emit('changed')}],"blur":function($event){return _vm.$forceUpdate()}}}),(errors.length > 0)?_c('div',{staticClass:"mt-1 text-sm text-red-700"},[_vm._v(_vm._s(errors[0]))]):_vm._e()])])]}}],null,false,2546373245)}):_vm._e()],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }